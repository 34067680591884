
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';
  import {prepareServerSideValidationErrors} from '@/helper/FormHelper';
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import {Country} from '@/model/Profile';
  import {sortArrays} from '@/helper/SortingHelper';
  import ModelItems from '@/mixins/ModelItems';

  export default {
    name: 'RegistrationForm',
    components: {
      RichText: () => import('@/components/elements/RichText'),
      CheckboxRichText: () => import('@/components/elements/CheckboxRichText'),
      DialogsBaseSection,
    },
    mixins: [clearableTabIndex, ModelItems, ScreenSize],
    props: {
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
    },
    data() {
      return {
        registrationData: {
          email: '',
          companyName: '',
          firstName: '',
          lastName: '',
          address: {
            street: '',
            number: '',
            zipcode: '',
            city: '',
            country: 'country.de',
          },
          vatId: '',
          phone: '',
          homepage: '',
          terms: false,
          advertisingConsent: null,
        },
        section: {},
        jobProviderLoginUrl: process.env.NUXT_ENV_LEGACY_URL,
        disabled: false,
        isEuCountry: false,
        euCountries: [
          'country.at',
          'country.be',
          'country.bg',
          'country.cy',
          'country.cz',
          'country.dk',
          'country.ee',
          'country.es',
          'country.fi',
          'country.fr',
          'country.gr',
          'country.hr',
          'country.hu',
          'country.ie',
          'country.it',
          'country.lt',
          'country.lu',
          'country.lv',
          'country.mt',
          'country.nl',
          'country.pl',
          'country.pt',
          'country.ro',
          'country.se',
          'country.si',
          'country.sk',
        ],
      };
    },
    async fetch() {
      this.section = await this.$cachedStrapi
        .find('organization-registration-dialog')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });

      this.registrationData.advertisingConsent =
        this.section && this.section.checkbox_advertising_consent
          ? false
          : null;
    },
    computed: {
      currentCountry() {
        return this.getCountryShortcut(this.registrationData.address.country);
      },
      sortedCountryItems() {
        const specialCountryItems = [
          'country.de',
          'country.at',
          'country.ch',
          'country.be',
          'country.dk',
          'country.gb',
          'country.fr',
          'country.it',
          'country.nl',
          'country.pl',
        ];
        return sortArrays(
          this.prepareModelItems(specialCountryItems),
          this.prepareModelItems(Country)
        );
      },
    },
    watch: {
      'registrationData.address.country': function (val) {
        if (val) {
          this.isEuCountry = this.euCountries.includes(val);
        }
      },
    },
    methods: {
      getCountryShortcut(string) {
        if (string) {
          const dotIndex = string.indexOf('.');
          if (dotIndex !== -1 && dotIndex < string.length - 1) {
            return string.substring(dotIndex + 1);
          }
        } else {
          return '';
        }
      },
      redirectToOrganizationPageLogin() {
        this.$emitWithClientData(Events.OrganizationRegistrationSwitchToLogin);
        window.open(this.jobProviderLoginUrl, '_blank');
      },
      registration() {
        this.disabled = true;
        this.$store
          .dispatch(
            'api/organizationProfile/postRegistration',
            this.registrationData
          )
          .then(() => {
            this.$notifications.showNotification({
              message: this.$t('organization.registration_success_in_form'),
              color: 'success',
              timeout: 3000,
            });
            this.$emitWithClientData(Events.OrganizationRegistered, {
              channel: 'default',
              position: this.position,
              advertisingConsent: this.registrationData.advertisingConsent,
              action: 'LoginAndRegistration',
            });
            this.$emitWithClientData(
              Events.OrganizationRegistrationStepFinished
            );
            this.$emitWithClientData(Events.OrganizationRegistrationFinished);
            this.resetDialog();
            this.$emit('change-dialog-page', 'registrationSuccess');
          })
          .catch((e) => {
            if (e.response.status !== 422) {
              throw e;
            }
            e.response.data.promotionbasis.violations.map((violation) => {
              const element = document.getElementById('registration-dialog');
              if (
                element &&
                (violation.code === 'afe78f6b-7932-50b3-8107-e14c5fa78a84' ||
                  violation.code === '9f059145-2557-fa9e-71f6-79c60ff98605')
              ) {
                element.scrollIntoView({behavior: 'smooth', block: 'start'});
              }
            });
            this.$refs.registrationFormObserver.setErrors(
              prepareServerSideValidationErrors(e.response)
            );
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
      resetDialog() {
        this.registrationData = {
          email: '',
          companyName: '',
          firstName: '',
          lastName: '',
          address: {
            street: '',
            number: '',
            zipcode: '',
            city: '',
            country: 'country.de',
          },
          vatId: '',
          phone: '',
          homepage: '',
          terms: false,
          advertisingConsent: null,
        };
      },
    },
  };
