export default [
  'country.ad',
  'country.ae',
  'country.af',
  'country.ag',
  'country.al',
  'country.am',
  'country.ao',
  'country.ar',
  'country.at',
  'country.au',
  'country.az',
  'country.ba',
  'country.bb',
  'country.bd',
  'country.be',
  'country.bf',
  'country.bg',
  'country.bh',
  'country.bi',
  'country.bj',
  'country.bn',
  'country.bo',
  'country.br',
  'country.bs',
  'country.bt',
  'country.bw',
  'country.by',
  'country.bz',
  'country.ca',
  'country.cd',
  'country.cf',
  'country.cg',
  'country.ch',
  'country.ci',
  'country.ck',
  'country.cl',
  'country.cm',
  'country.cn',
  'country.co',
  'country.cr',
  'country.cu',
  'country.cv',
  'country.cy',
  'country.cz',
  'country.de',
  'country.dj',
  'country.dk',
  'country.dm',
  'country.do',
  'country.dz',
  'country.ec',
  'country.ee',
  'country.eg',
  'country.er',
  'country.es',
  'country.et',
  'country.fi',
  'country.fj',
  'country.fm',
  'country.fr',
  'country.ga',
  'country.gb',
  'country.gd',
  'country.ge',
  'country.gh',
  'country.gm',
  'country.gn',
  'country.gq',
  'country.gr',
  'country.gt',
  'country.gw',
  'country.gy',
  'country.hk',
  'country.hn',
  'country.hr',
  'country.ht',
  'country.hu',
  'country.id',
  'country.ie',
  'country.il',
  'country.in',
  'country.iq',
  'country.ir',
  'country.is',
  'country.it',
  'country.jm',
  'country.jo',
  'country.jp',
  'country.ke',
  'country.kg',
  'country.kh',
  'country.ki',
  'country.km',
  'country.kn',
  'country.kp',
  'country.kr',
  'country.kw',
  'country.kz',
  'country.la',
  'country.lb',
  'country.lc',
  'country.li',
  'country.lk',
  'country.lr',
  'country.ls',
  'country.lt',
  'country.lu',
  'country.lv',
  'country.ly',
  'country.ma',
  'country.mc',
  'country.md',
  'country.me',
  'country.mg',
  'country.mh',
  'country.mk',
  'country.ml',
  'country.mm',
  'country.mn',
  'country.mr',
  'country.mt',
  'country.mu',
  'country.mv',
  'country.mw',
  'country.mx',
  'country.my',
  'country.mz',
  'country.na',
  'country.ne',
  'country.ng',
  'country.ni',
  'country.nl',
  'country.no',
  'country.np',
  'country.nr',
  'country.nz',
  'country.om',
  'country.pa',
  'country.pe',
  'country.pg',
  'country.ph',
  'country.pk',
  'country.pl',
  'country.pt',
  'country.pw',
  'country.py',
  'country.qa',
  'country.ro',
  'country.rs',
  'country.ru',
  'country.rw',
  'country.sa',
  'country.sb',
  'country.sc',
  'country.sd',
  'country.se',
  'country.sg',
  'country.si',
  'country.sk',
  'country.sl',
  'country.sm',
  'country.sn',
  'country.so',
  'country.sr',
  'country.st',
  'country.sv',
  'country.sy',
  'country.sz',
  'country.td',
  'country.tg',
  'country.th',
  'country.tj',
  'country.tl',
  'country.tm',
  'country.tn',
  'country.to',
  'country.tr',
  'country.tt',
  'country.tv',
  'country.tw',
  'country.tz',
  'country.ua',
  'country.ug',
  'country.us',
  'country.uy',
  'country.uz',
  'country.va',
  'country.vc',
  'country.ve',
  'country.vn',
  'country.vu',
  'country.ws',
  'country.ye',
  'country.za',
  'country.zm',
  'country.zw',
];
