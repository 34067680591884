export default [
  'industry.advertising',
  'industry.airports_airlines',
  'industry.automobile',
  'industry.automobile_club',
  'industry.automobile_electronics',
  'industry.building_material_trading',
  // 'industry.car_navigation_systems',
  // 'industry.care_products',
  // 'industry.clothing',
  'industry.computer',
  'industry.consumer_electronics',
  'industry.culture',
  'industry.culture_clubs',
  'industry.culture_museum',
  'industry.culture_music',
  'industry.education',
  // 'industry.electric_power_tools',
  'industry.electronics',
  'industry.electronics_hardware_tools',
  'industry.energy',
  'industry.engineering',
  //'industry.entertainment',
  'industry.finance',
  'industry.finance_bank',
  'industry.finance_credit_cards',
  'industry.finance_discount_cards',
  'industry.finance_insurance',
  'industry.food',
  'industry.food_drinks',
  'industry.food_foodstuff',
  'industry.food_gastronomy',
  'industry.food_tobaccos',
  //'industry.fundraising',
  'industry.furniture',
  'industry.hardware',
  'industry.lifestyle',
  'industry.lifestyle_accessoires',
  'industry.lifestyle_cosmetics',
  'industry.lifestyle_mode',
  'industry.logistics',
  'industry.market_research',
  'industry.media',
  'industry.media_internet',
  'industry.media_it',
  'industry.media_tv_print',
  'industry.medicine',
  'industry.npo',
  // 'industry.office_supplies',
  // 'industry.paddle',
  'industry.pet_accessories',
  'industry.photo',
  //'industry.public_transport',
  //'industry.real_estate',
  'industry.sport_goods',
  'industry.telecom',
  'industry.tourism',
  'industry.toys',
  //'industry.watches',
  //'industry.wellness',
  'industry.misc',
  'industry.various',
];
