export function sortArrays(specialItems = [], items = []) {
  items.forEach(function (item, index) {
    if (specialItems.includes(item)) {
      items.splice(index, 1);
    }
  });

  items.sort(function (a, b) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  return specialItems.concat(items);
}
