export default [
  //'activity.animation',
  'activity.aufbauhelfer',
  'activity.buchhaltung',
  'activity.callcenter',
  'activity.deko',
  //'activity.dj',
  'activity.entwicklung',
  'activity.fachberatung',
  'activity.fahrer',
  'activity.flyerverteiler',
  'activity.fundraising',
  'activity.gastronomie',
  'activity.grafik',
  'activity.influencer',
  'activity.interviewer',
  'activity.komparse',
  //'activity.kosmetik',
  'activity.kundenbetreuung',
  'activity.leasing',
  'activity.leitung',
  //'activity.lichtdouble',
  'activity.merchandising',
  'activity.messehost',
  //'activity.model',
  'activity.moderation',
  'activity.mystery_shopping',
  'activity.online_marketing',
  'activity.personalmanagement',
  'activity.projektmanagement',
  'activity.redaktion',
  'activity.sampling',
  'activity.security',
  'activity.sekreteriat',
  'activity.social_media',
  'activity.supervisor',
  'activity.verkostung',
  'activity.vertrieb',
  'activity.verwaltung',
  'activity.viphost',
  //'activity.walking_act',
  'activity.misc',
  'activity.various',
];
