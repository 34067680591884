export default {
  methods: {
    prepareModelItems(items) {
      return items.map((item) => ({
        value: item,
        text: this.$t(item),
      }));
    },
    prepareModelItemsWithCounter(items) {
      const prepared_items = [];
      Object.entries(items).forEach((entry) => {
        const [key, value] = entry;
        prepared_items.push({
          value: key,
          text: this.$t(key),
          counter: value,
        });
      });

      return prepared_items;
    },
  },
};
