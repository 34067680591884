export function prepareServerSideValidationErrors(response) {
  const serverValidationErrors = response.data.promotionbasis.violations;
  const validationErrors = {};
  for (const violation of serverValidationErrors) {
    if (!(violation.propertyPath in validationErrors)) {
      validationErrors[violation.propertyPath] = [];
    }
    validationErrors[violation.propertyPath].push(violation.message);
  }
  return validationErrors;
}

export function prepareMultipleElementData(elements, dataArray) {
  if (!elements) {
    return;
  }
  for (const [key, element] of Object.entries(elements)) {
    if (key === 'hydra:view') {
      continue;
    }
    dataArray.push(Object.assign({}, element));
  }
}

export function prepareMultipleSelectData(elements, dataArray) {
  if (!elements) {
    return;
  }

  for (const [key, element] of Object.entries(elements)) {
    if (key === 'hydra:view') {
      continue;
    }
    dataArray.push(element);
  }
}
