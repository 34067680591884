/*eslint sort-exports/sort-exports: "error"*/
export const ActivitySkillsLevel = [
  'skill_level.beginner',
  'skill_level.advanced',
  'skill_level.expert',
];

export {default as Country} from '@/model/Country';

export const Degree = [
  'degree.isced_2',
  'degree.isced_24',
  'degree.isced_3',
  'degree.isced_5',
  'degree.isced_6',
  'degree.isced_7',
  'degree.isced_8',
  'degree.isced_none',
  'degree.isced_9',
];

export const Denomination = [
  'denomination.ak',
  'denomination.ap',
  'denomination.ba',
  'denomination.bd',
  'denomination.ca',
  'denomination.ce',
  'denomination.di',
  'denomination.ev',
  'denomination.fa',
  'denomination.fb',
  'denomination.fm',
  'denomination.fo',
  'denomination.fp',
  'denomination.fr',
  'denomination.gf',
  'denomination.go',
  'denomination.ht',
  'denomination.ib',
  'denomination.if',
  'denomination.il',
  'denomination.is',
  'denomination.iw',
  'denomination.jd',
  'denomination.ka',
  'denomination.lt',
  'denomination.lu',
  'denomination.me',
  'denomination.ml',
  'denomination.mt',
  'denomination.na',
  'denomination.ob',
  'denomination.ox',
  'denomination.pr',
  'denomination.rf',
  'denomination.rk',
  'denomination.ro',
  'denomination.so',
  'denomination.un',
  'denomination.wr',
  'denomination.zj',
];

export const DocumentLicenseCertificateType = [
  'profile_document_type.business_license',
  'profile_document_type.enrollment_certificate',
];

export const DocumentType = [
  'profile_document_type.bfa_befreiung',
  'profile_document_type.business_license',
  'profile_document_type.certificate',
  'profile_document_type.criminal_record',
  'profile_document_type.cv',
  'profile_document_type.driving_license',
  'profile_document_type.enrollment_certificate',
  'profile_document_type.health_certificate',
  'profile_document_type.identification_card',
  'profile_document_type.tax_card',
  'profile_document_type.tax_confirmation',
  'profile_document_type.work_permit',
  'profile_document_type.misc',
];

export const DrivingLicense = [
  'driving_license.a',
  'driving_license.a1',
  'driving_license.b',
  'driving_license.be',
  'driving_license.c',
  'driving_license.ce',
  'driving_license.c1',
  'driving_license.c1e',
  'driving_license.d',
  'driving_license.de',
  'driving_license.d1',
  'driving_license.d1e',
  'driving_license.l',
  'driving_license.m',
  'driving_license.t',
  'driving_license.old_1',
  'driving_license.old_2',
  'driving_license.old_3',
];

export const EyeColor = [
  'eye_color.blue',
  'eye_color.brown',
  'eye_color.gray',
  'eye_color.green',
  'eye_color.powder_blue',
];

export {default as Gender} from '@/model/Gender';

export const HairColor = [
  'hair_color.light_blonde',
  'hair_color.blonde',
  'hair_color.dark_blonde',
  'hair_color.brunette',
  'hair_color.light_brown',
  'hair_color.brown',
  'hair_color.dark_brown',
  'hair_color.red',
  'hair_color.gray',
  'hair_color.black',
  'hair_color.bald',
];

export const HairLength = [
  'hair_length.none',
  'hair_length.short',
  'hair_length.middle',
  'hair_length.long',
];

export const HealthInsuranceType = [
  'health_insurance_type.private',
  'health_insurance_type.statutory',
  'health_insurance_type.voluntary',
];

export const JobEmploymentStatus = [
  'job_employment_status.scholar',
  'job_employment_status.student',
  'job_employment_status.trainee',
  'job_employment_status.voluntary_year',
  'job_employment_status.between_school_and_training',
  'job_employment_status.between_school_and_study',
  'job_employment_status.between_school_and_work',
  'job_employment_status.employee_full_time',
  'job_employment_status.employee_part_time',
  'job_employment_status.minijobber',
  'job_employment_status.freelancer',
  'job_employment_status.homemaker',
  'job_employment_status.pensioner',
  'job_employment_status.unemployed',
  'job_employment_status.misc',
];

export const Language = [
  'language.aa',
  'language.ab',
  'language.af',
  'language.am',
  'language.ar',
  'language.as',
  'language.ay',
  'language.az',
  'language.ba',
  'language.be',
  'language.bg',
  'language.bh',
  'language.bi',
  'language.bn',
  'language.bo',
  'language.br',
  'language.bs',
  'language.ca',
  'language.co',
  'language.cs',
  'language.cy',
  'language.da',
  'language.de',
  'language.dz',
  'language.el',
  'language.en',
  'language.eo',
  'language.es',
  'language.et',
  'language.eu',
  'language.fa',
  'language.fi',
  'language.fj',
  'language.fo',
  'language.fr',
  'language.fy',
  'language.ga',
  'language.gd',
  'language.gl',
  'language.gn',
  'language.gu',
  'language.ha',
  'language.he',
  'language.hi',
  'language.hr',
  'language.hu',
  'language.hy',
  'language.ia',
  'language.id',
  'language.ie',
  'language.ik',
  'language.is',
  'language.it',
  'language.iu',
  'language.ja',
  'language.ji',
  'language.jv',
  'language.ka',
  'language.kk',
  'language.kl',
  'language.km',
  'language.kn',
  'language.ko',
  'language.ks',
  'language.ku',
  'language.ky',
  'language.la',
  'language.ln',
  'language.lo',
  'language.lt',
  'language.lv',
  'language.mg',
  'language.mi',
  'language.mk',
  'language.ml',
  'language.mn',
  'language.mo',
  'language.mr',
  'language.ms',
  'language.mt',
  'language.my',
  'language.na',
  'language.ne',
  'language.nl',
  'language.no',
  'language.oc',
  'language.om',
  'language.or',
  'language.pa',
  'language.pl',
  'language.ps',
  'language.pt',
  'language.qu',
  'language.rm',
  'language.rn',
  'language.ro',
  'language.ru',
  'language.rw',
  'language.sa',
  'language.sd',
  'language.sg',
  'language.sh',
  'language.si',
  'language.sk',
  'language.sl',
  'language.sm',
  'language.sn',
  'language.so',
  'language.sq',
  'language.sr',
  'language.ss',
  'language.st',
  'language.su',
  'language.sv',
  'language.sw',
  'language.ta',
  'language.te',
  'language.tg',
  'language.th',
  'language.ti',
  'language.tk',
  'language.tl',
  'language.tn',
  'language.to',
  'language.tr',
  'language.ts',
  'language.tt',
  'language.tw',
  'language.ug',
  'language.uk',
  'language.ur',
  'language.uz',
  'language.vi',
  'language.vo',
  'language.wo',
  'language.xh',
  'language.yi',
  'language.yo',
  'language.za',
  'language.zh',
  'language.zu',
  'language.de-ch',
  'language.de-at',
  'language.en-us',
  'language.en-ca',
  'language.en-au',
  'language.en-gb',
  'language.en-uk',
  'language.cel-gaulish',
  'language.fr-ca',
  'language.zh-tw',
  'language.zh-guoyu',
  'language.zh-min-nan',
  'language.zh-xiang',
];

export const LanguageLevel = [
  'language_level.basic',
  'language_level.advanced',
  'language_level.expert',
  'language_level.native',
];

export {default as PreferredActivity} from '@/model/Activity';

export {default as PreferredEmploymentType} from '@/model/EmploymentType';

export {default as PreferredIndustry} from '@/model/Industry';

export const SizeOfShirt = [
  'size_of_shirt.xs',
  'size_of_shirt.s',
  'size_of_shirt.m',
  'size_of_shirt.l',
  'size_of_shirt.xl',
  'size_of_shirt.xxl',
];

export const SocialProfileType = [
  'social_profile_type.facebook',
  'social_profile_type.google',
  'social_profile_type.instagram',
  'social_profile_type.twitter',
  'social_profile_type.linkedin',
  'social_profile_type.snapchat',
  'social_profile_type.tiktok',
  'social_profile_type.xing',
  'social_profile_type.misc',
];

export const WorkingPlace = [
  'working_place.home',
  'working_place.regional_home',
  'working_place.regional_sleep',
  'working_place.nationwide',
  'working_place.abroad',
  // 'working_place.radius_50',
  // 'working_place.radius_150',
  // 'working_place.radius_250',
  // 'working_place.radius_500',
  // 'working_place.radius_any',
];

export const WorkingTime = [
  'working_time.day',
  'working_time.evening',
  'working_time.weekdays',
  'working_time.weekend',
  'working_time.holidays',
  'working_time.always',
  'working_time.arrangement',
];
